import React, { Component } from 'react';
import "./CSS files/errorboundry.css"
import "./CSS files/pagenotfound.css";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  handleBackToHome = () => {
    // Navigate back to the home page
    window.location.href = "/";
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h1>Something went wrong. Please refresh the page.</h1>
          <button className="back-to-projects contact" onClick={this.handleBackToHome}>
            Get back on track
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
