import React, { useState } from 'react';
import OtherWorks from '../Otherworks.js';
import '../CSS files/Playground.css';

const Playground = () => {
  const [selectedItem, setSelectedItem] = useState(null); // Track selected item
  const [isOverlayOpen, setIsOverlayOpen] = useState(false); // Track overlay visibility

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
    setSelectedItem(null);
  };

  return (
    <div className="playground-container">
      <h1 className="playground-title">Playground ✨</h1>
      <p className="playground-description">
        Cool things that didn't make it to the projects
      </p>

      <div className="playground-grid">
        {OtherWorks.map((item, index) => (
          <div
            key={index}
            className="playground-item"
            onClick={() => handleItemClick(item)}
          >
            {item.video ? (
              <video src={item.video} alt={item.name} loop autoPlay muted className="media" />
            ) : (
              <img src={item.image} alt={item.name} className="media" />
            )}
            {/* work on this */}
            <div>
            <p className="media-name">{item.name}</p>
            <p className="media-year">{item.year}</p>

            </div>
            
          </div>
        ))}
      </div>

      {isOverlayOpen && (
        <div className="overlay">
          <button className="close-button" onClick={handleCloseOverlay}>
            &times;
          </button>
          <div className="overlay-content">
            {selectedItem.video ? (
              <video src={selectedItem.video} alt={selectedItem.name} controls autoPlay className="overlay-media" />
            ) : (
              <img src={selectedItem.image} alt={selectedItem.name} className="overlay-media" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Playground;
