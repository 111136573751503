
const OtherWorks = [
  {
    name: "Dear Diary",
    image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1730596327/Frame_1707479821_usxadj.webp',
    year: "2024",
  },

  {
    name: "Aura Woman",
    image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1730596327/Frame_1707479822_axgxaq.webp',
    year: "2024",
  },
    {
      name: "Identify",
      image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1730596302/Frame_1707479824_i7vkg4.webp',
      year: "2022",
    },

    {
      name: "Fast Finance",
      video: 'https://res.cloudinary.com/dyqg8luud/video/upload/v1730600221/1681802726595_wcwoec.webm',
      year: "2023",
    },
    {
      name: "Harrows",
      video: 'https://res.cloudinary.com/dyqg8luud/video/upload/v1730600198/teoKraDw7kpPNgnT_ovdk22.webm',
      year: "2023",
    },

    {
      name: "Freelancer",
      image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753893/112_prpfrm.jpg',
      year: "2024",
    },
   
    {
      name: "Market Run",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1730596329/Frame_1707479826_qqfn3x.webp',
        year: "2024",
      },
      {
        name: "Raft",
        video: 'https://res.cloudinary.com/dyqg8luud/video/upload/v1730600186/I8qS_Ro3OKAaBZ9Y_zkzbb6.webm',
        year: "2023",
      },
  
      
      {
        name: "Raft",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753892/115_ahgubq.png',
        year: "2023",
      },
    //   6-10

      {
        name: "Freelancer",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753887/113_dll2gz.jpg',
        year: "2024",

      },
      {
        name: "Urban Pulse",
          image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1730596302/Frame_1707479818_cj4v9f.webp',
          year: "2024",
        },
      {
        name: "Market Run",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1730596302/Frame_1707479819_iqucsk.webp',
        year: "2024",
      },
      {
        name: "Finance Fast",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753974/125_bqxoa7.jpg',
        year: "2023",
        
      },
      {
        name: "Raft",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721753944/116_svb7il.png',
        year: "2023",
        
      },
      {
        name: "Crossgate",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1730596327/Frame_1707479820_uj2b19.webp',
        year: "2024",
        
      },
      {
        name: "CSD",
        image: 'https://res.cloudinary.com/dyqg8luud/image/upload/v1721755736/129_fj179v.png',
        year: "2023",
      },
     
      
      

  ];
  
  export default OtherWorks;
  