import React, { useEffect, useMemo, useState } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./CSS files/projectspage.css";

const Projectpage = ({ projects }) => {
  const { projectId } = useParams();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectId]);

  const project = useMemo(
    () => projects.find((proj) => proj.id.toString() === projectId),
    [projects, projectId]
  );

  const { previousProject, nextProject } = useMemo(() => {
    const currentIndex = projects.findIndex(
      (proj) => proj.id.toString() === projectId
    );
    return {
      previousProject: currentIndex > 0 ? projects[currentIndex - 1] : null,
      nextProject:
        currentIndex < projects.length - 1 ? projects[currentIndex + 1] : null,
    };
  }, [projects, projectId]);

  if (!project) {
    return <Navigate to="*" />;
  }

  const openOverlay = (item) => {
    setSelectedItem(item);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setSelectedItem(null);
  };

  const pageVariants = {
    initial: { opacity: 0, y: 100 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -100 },
  };

  const pageTransition = {
    type: "tween",
    ease: "easeInOut",
    duration: 1,
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <section className="projects-page">
        <div className="project-header">
          <h2>{project.title}</h2>
          <p className="description-prj">{project.description}</p>
        </div>

        <div className="main-project-image">
          <img src={project.image} loading="lazy" alt={project.title} />
        </div>

        <div className="project-content-wrapper">
               {/* intro */}
        <div className="project-meta">
              <div className="meta-item">
                <h4>My role</h4>
                <p>{project.role.join(", ")}</p>
              </div>
              <div className="meta-item">
                <h4>Industry</h4>
                <p>{project.industry}</p>
              </div>
              <div className="meta-item">
                <h4>Year</h4>
                <p>{project.year}</p>
              </div>
             
            
            </div>

        </div>

        <div className="projects-body-text">
  <h3>About</h3>
  <p>{project.about}</p>

  {project.website && (
    <Link
      to={project.website}
      className="visit-website"
      target="_blank"
      rel="noopener noreferrer"
    >
      {project.cta}
    </Link>
  )}

{project.problemstatement && (
    <>
      <h3>Problem statement</h3>
      <p>{project.problemstatement}</p>
    </>
  )}

{project.projectgoals && (
    <>
      <h3>Project goals</h3>
      <ul className="problem-state">
            {project.projectgoals.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ul>
    </>
  )}

{project.challenge1 && (
  <>
    <h3>Challenges</h3>
    <p>{project.challenge1}</p>
    {project.challenge2 && <p>{project.challenge2}</p>}
  </>
)}

{project.solution && (
    <>
      <h3>Crafting a Solution</h3>
      <p>{project.solution}</p>
      {project.solution2 && <p>{project.solution2}</p>}
    </>
  )}
{/* workoverview */}

{project.workoverview && (
    <>
      <h3>Work Done</h3>
      <p>{project.workoverview}</p>
      {project.workoverview2 && <p>{project.workoverview2}</p>}
      {project.workoverview3 && <p>{project.workoverview3}</p>}
    </>
  )}




</div>

{/* HIIIII */}
   {/* Gallery */}
        <div className="gallery-container-wrapper">
          <div className="gallery-container">
            {project.gallery.map((item, index) => (
              <div key={index} className="gallery-item">
                <img
                  src={item.image}
                  alt={`Gallery ${index + 1}`}
                  loading="lazy"
                  className="gallery-image"
                  onClick={() => openOverlay(item)}
                />
                <p className="gallery-text">{item.text}</p>
              </div>
            ))}
          </div>
        </div>

  {/* Overlay */}
  {isOverlayOpen && (
          <div className="overlay" onClick={closeOverlay}>
            <button className="close-button" onClick={closeOverlay}>
              &times;
            </button>
            <div className="overlay-content">
              {selectedItem.video ? (
                <video
                  src={selectedItem.video}
                  alt={selectedItem.name}
                  controls
                  autoPlay
                  className="overlay-media"
                />
              ) : (
                <img
                  src={selectedItem.image}
                  alt={selectedItem.name}
                  className="overlay-media"
                />
              )}
            </div>
          </div>
        )}

<div className="projects-body-text">
  {project.lookingahead && (
    <>
      <h3>Reflecting on {project.title}</h3>
      <p>{project.lookingahead}</p>
      {project.lookingahead2 && <p>{project.lookingahead2}</p>}
    </>
  )}
</div>


        <div className="navigation-links-wrapper">
          <div className="navigation-links">
            {previousProject && (
              <Link to={`/projects/${previousProject.id}`} className="nav-link">
                Previous: {previousProject.title}
              </Link>
            )}
            {nextProject && (
              <Link to={`/projects/${nextProject.id}`} className="nav-link">
                Next: {nextProject.title}
              </Link>
            )}
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Projectpage;
