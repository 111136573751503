import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import arrowup from "../icons/arrow-up-right.svg";
import "../CSS files/Navbar.css";

const Navbarr = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [menuOpen]);

  const scrollToSection = (sectionId) => {
    setMenuOpen(false);
    if (location.pathname === "/") {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(`/#${sectionId}`);
    }
    // Force a re-render by updating the URL
    window.history.pushState({}, '', `/#${sectionId}`);
  };

  return (
    <section className="navbar-head">
      <div id="nav-items" className={menuOpen ? "open" : ""}>
        <NavLink
          exact
          to="/"
          className="signature nav-text"
          onClick={() => setMenuOpen(false)}
        >
          Adeola Adekoya
        </NavLink>

        <div id="navigation-options">
          <div className="link-text">
            <NavLink
              to="/about"
              className="regular-text"
              onClick={() => setMenuOpen(false)}
            >
              About
            </NavLink>
            <button
              className="regular-text"
              onClick={() => scrollToSection("section-4")}
            >
              Projects
            </button>
            <NavLink
              to="/playground"
              className="regular-text"
              onClick={() => setMenuOpen(false)}
            >
              Playground
            </NavLink>

          </div>

          <a
            href="mailto:adeolaadekoya11@gmail.com"
            className="contact-link desktop-contact"
          >
            <button className="contact">
              <span>Contact</span>
              <img src={arrowup} alt="contact arrow" className="svg-icon" />
              <div className="absolute-rectangle"></div>
            </button>
          </a>
        </div>

        <div
          className={`hamburger-menu ${menuOpen ? "open" : ""}`}
          onClick={handleMenuToggle}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        <div className={`mobile-menu-overlay ${menuOpen ? "open" : ""}`}>
          <div className="mobile-menu-content">
            <NavLink
              to="/about"
              className="mobile-menu-item"
              onClick={() => setMenuOpen(false)}
            >
              About
            </NavLink>
            <button
              className="mobile-menu-item button-projects-nav"
              onClick={() => scrollToSection("section-4")}
            >
              Projects
            </button>
            <NavLink
              to="/playground"
              className="mobile-menu-item"
              onClick={() => setMenuOpen(false)}
            >
              Playground
            </NavLink>
            <a
              href="mailto:adeolaadekoya11@gmail.com"
              className="mobile-menu-item contact-link"
              onClick={() => setMenuOpen(false)}
            >
              Contact
            </a>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default Navbarr;